<template>
   <div>
      <router-view />
   </div>
</template>

<script>
let frappe = null;

export default {
   name: 'Home',
   props: {},
   data(){
      return {
          title: 'Home'
      }
   },
   components: {},
   methods: {
    frappe(){
        return this.$frappe;
    }
   }
}
</script>
